import { render, staticRenderFns } from "./SideBanner.vue?vue&type=template&id=5f62c9bd"
import script from "./SideBanner.vue?vue&type=script&lang=js"
export * from "./SideBanner.vue?vue&type=script&lang=js"
import style0 from "./SideBanner.vue?vue&type=style&index=0&id=5f62c9bd&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeViewBannerSideBannerItem: require('/var/www/html/js/productlisting/components/templates/home/view/banner/SideBanner/Item.vue').default})
