
export default {
  name: 'HomeViewBannerSection',
  props: {
    bannerItems: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    splitLargeText() {
      return (text) => {
        if (!text) return ['', ''];
        const words = text.split(' ');
        if (words.length < 2) return [text, ''];
        return [words[0], words.slice(1).join(' ')];
      };
    },
  },
};
