
import respUtility from '@/mixins/respUtility';

export default {
  name: 'HomeViewBannerBottomBanner',
  mixins: [respUtility],
  props: {
    bannerItems: {
      type: Array,
      default() {
        return [];
      },
    },
    device: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    deviceSpecificBanner() {
      let cb;

      if (this?.device?.isMobile) {
        cb = (banner) => banner?.isMobile;
      } else if (this?.device?.isTablet) {
        cb = (banner) => banner?.isTablet;
      } else {
        cb = (banner) => banner?.isDesktop;
      }

      return this.bannerItems.filter(cb);
    },
  },
};
