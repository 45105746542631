// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bannersSection_zUwSI{margin:0 auto;max-width:1648px;padding:1.6rem 1.6rem 4rem}@media only screen and (min-width:768px){.bannersSection_zUwSI{padding:2.4rem 2.4rem 3.2rem}}@media only screen and (min-width:1264px){.bannersSection_zUwSI{padding-bottom:4.8rem;padding-top:2.4rem}}.placeReservation_4ZqBS{padding-top:100%;pointer-events:none}.placeReservation_4ZqBS.isTypeOne_TsQDP{--main-banner-w:686;--main-banner-h:847;--bottom-banner-w:0;--bottom-banner-h:128;--horizontal-gap:1.6rem;--main-resv:calc(var(--main-banner-h, 1)/var(--main-banner-w, 1)*100%);--bottom-resv:calc(var(--bottom-banner-h)*1px);padding-top:calc(123.46939% + 1.6rem + 128px);padding-top:calc(var(--main-resv) + var(--horizontal-gap) + var(--bottom-resv))}@media only screen and (min-width:768px){.placeReservation_4ZqBS.isTypeOne_TsQDP{--main-banner-w:1440;--main-banner-h:1214;--bottom-banner-w:448;--bottom-banner-h:192;--horizontal-gap:1.2rem;--bottom-resv:calc(var(--bottom-banner-h, 1)/var(--bottom-banner-w, 1)*(100% - 49px)/3 + 12px)}}@media only screen and (min-width:1264px){.placeReservation_4ZqBS.isTypeOne_TsQDP{--main-banner-w:3200;--main-banner-h:1164;--bottom-banner-w:1032;--bottom-banner-h:416;--horizontal-gap:1.6rem}}.innerOne_vaMWH{position:relative}.innerTwo_p1xb7{left:0;position:absolute;right:0;top:0}@media only screen and (min-width:1264px){.banners_WnPpW{display:flex;gap:2.4rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannersSection": "bannersSection_zUwSI",
	"placeReservation": "placeReservation_4ZqBS",
	"isTypeOne": "isTypeOne_TsQDP",
	"innerOne": "innerOne_vaMWH",
	"innerTwo": "innerTwo_p1xb7",
	"banners": "banners_WnPpW"
};
module.exports = ___CSS_LOADER_EXPORT___;
