// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".side_banner_tdcBp{display:none;margin:0}@media only screen and (min-width:1264px){.side_banner_tdcBp{display:flex;flex:1 0 auto;flex-wrap:wrap;gap:24px;width:23.75%}}.side_banner_tdcBp a{background-color:var(--cp-gray-9);width:100%}.side_banner_tdcBp a:focus-visible{border-radius:.8rem}.side_banner_tdcBp img{vertical-align:middle}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"side_banner": "side_banner_tdcBp"
};
module.exports = ___CSS_LOADER_EXPORT___;
