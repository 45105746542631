
export default {
  name: 'HomeViewBannerCenterBannerItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleVideoEnd() {
      this.$emit('video-ended', this.index);
    },
  },
};
