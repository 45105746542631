import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=12db2168"
import script from "./Banner.vue?vue&type=script&lang=js"
export * from "./Banner.vue?vue&type=script&lang=js"
import style0 from "./Banner.vue?vue&type=style&index=0&id=12db2168&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeSkeletonBanner: require('/var/www/html/js/productlisting/components/templates/home/skeleton/Banner.vue').default,HomeViewBannerLeftBanner: require('/var/www/html/js/productlisting/components/templates/home/view/banner/LeftBanner.vue').default,HomeViewBannerSideBanner: require('/var/www/html/js/productlisting/components/templates/home/view/banner/SideBanner.vue').default,HomeViewBannerCenterBannerStatic: require('/var/www/html/js/productlisting/components/templates/home/view/banner/CenterBannerStatic.vue').default,HomeViewBannerCenterBannerDynamic: require('/var/www/html/js/productlisting/components/templates/home/view/banner/CenterBannerDynamic.vue').default,HomeViewBannerRightBanner: require('/var/www/html/js/productlisting/components/templates/home/view/banner/RightBanner.vue').default,HomeViewBannerSection: require('/var/www/html/js/productlisting/components/templates/home/view/banner/Section.vue').default,HomeViewBannerBottomBanner: require('/var/www/html/js/productlisting/components/templates/home/view/banner/BottomBanner.vue').default})
