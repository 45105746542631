
import { GET_BANNERS_DYNAMIC, GET_BANNERS_STATIC, GET_BANNER_TYPE } from '@/api/apolloHomePageQueries';

export default {
  name: 'HomeViewBanner',
  data() {
    return {
      banners: {},
      isLoading: false,
      useStaticBanner: true,
    };
  },
  props: {
    device: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true;
        const response = await this.$graphql.default.request(GET_BANNER_TYPE) || {};
        if (response?.home) {
          this.useStaticBanner = response?.home?.useStaticBanner;
        }
        if (this.useStaticBanner) {
          const { home } = await this.$graphql.default.request(GET_BANNERS_STATIC) || {};
          if (home?.staticBanner) {
            this.banners = home?.staticBanner;
          } else {
            console.error('Invalid response format:', home);
          }
        } else {
          const { home } = await this.$graphql.default.request(GET_BANNERS_DYNAMIC) || {};
          if (home?.banner) {
            this.banners = home?.banner;
            const processBanners = (banners) => banners.filter((banner) => banner?.displayPage === 'home')
              .map((banner) => ({
                ...banner,
                content: {
                  html: banner.content.html
                    .replace('{{BACKGROUND}}', banner?.background?.banner ?? '')
                    .replace('{{VIDEO}}', banner?.background?.video ?? '')
                    .replace('{{ALT}}', banner?.background?.alt ?? '')
                    .replace('{{ALT}}', banner?.background?.alt ?? ''),
                  style: banner.content.style,
                },
              }));

            if (this?.banners?.center) {
              this.banners.center = processBanners(this.banners.center);
            }
            if (this?.banners?.left) {
              this.banners.left = processBanners(this.banners.left);
            }
            if (this?.banners?.right) {
              this.banners.right = processBanners(this.banners.right);
            }
            if (this?.banners?.bottom) {
              this.banners.bottom = processBanners(this.banners.bottom);
            }
          } else {
            console.error('Invalid response format:', home);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  async fetch() {
    await this.fetchData();
  },
};
