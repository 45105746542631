// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bottom_banner_bb9Er{display:flex;flex-direction:row;margin:-4px -16px -8px;overflow-x:auto}.bottom_banner_bb9Er::-webkit-scrollbar{display:none}@media only screen and (min-width:768px){.bottom_banner_bb9Er{margin:0 -12px;overflow-x:visible}}@media only screen and (min-width:1264px){.bottom_banner_bb9Er{display:flex;flex:1 0 auto;flex-wrap:wrap;gap:0;width:auto}}.bottom_banner_bb9Er a{flex:0 0 324px;padding:.4rem .8rem .8rem 1.6rem}@media only screen and (min-width:768px){.bottom_banner_bb9Er a{flex:1;padding:0 12.25px 12px}}@media only screen and (min-width:1264px){.bottom_banner_bb9Er a{flex:1;padding:8px 12.25px 12px}}.bottom_banner_bb9Er a:nth-child(2){flex:0 0 316px;padding:.4rem .8rem .8rem}@media only screen and (min-width:768px){.bottom_banner_bb9Er a:nth-child(2){flex:1;padding:0 12.25px 12px}}@media only screen and (min-width:1264px){.bottom_banner_bb9Er a:nth-child(2){flex:1;padding:8px 12.25px 12px}}.bottom_banner_bb9Er a:nth-child(3){flex:0 0 324px;padding:.4rem 1.6rem .8rem .8rem}@media only screen and (min-width:768px){.bottom_banner_bb9Er a:nth-child(3){flex:1;padding:0 12.25px 12px}}@media only screen and (min-width:1264px){.bottom_banner_bb9Er a:nth-child(3){flex:1;padding:8px 12.25px 12px}}@media only screen and (min-width:768px){.bottom_banner_bb9Er a{flex:1}}.bottom_banner_bb9Er a img{vertical-align:middle}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bottom_banner": "bottom_banner_bb9Er"
};
module.exports = ___CSS_LOADER_EXPORT___;
