
export default {
  name: 'HomeViewBannerRightBanner',
  props: {
    bannerItems: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    splitFirstWord(text) {
      if (!text) return ['', ''];
      const words = text.split(' ');
      if (words.length < 2) return [text, ''];
      return [words[0], words.slice(1).join(' ')];
    },
    splitLastWord(text) {
      if (!text) return ['', ''];
      const words = text.split(' ');
      if (words.length < 2) return [text, ''];
      return [words.slice(0, words.length - 1).join(' '), words[words.length - 1]];
    },
  },
};
