
import KeenSlider from 'keen-slider';
import 'keen-slider/keen-slider.min.css';
import respUtility from '@/mixins/respUtility';

export default {
  name: 'HomeViewBannerCenterBannerStatic',
  mixins: [respUtility],
  props: {
    bannerItems: {
      type: Object,
      default() {
        return {};
      },
    },
    device: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      slider: null,
      current: 0,
    };
  },
  computed: {
    affirmData() {
      let affirm = {};
      if (this.bannerItems?.banners) {
        affirm = this.bannerItems?.banners[0];
      }
      return affirm;
    },
    dotHelper() {
      return this.slider ? [...Array(this?.slider?.track?.details?.slides?.length).keys()] : [];
    },
    currentCssClass() {
      let cssClass = '';
      if (this?.device?.isDesktop) {
        cssClass = 'desktop';
      } else if (this?.device?.isTablet) {
        cssClass = 'tablet';
      } else if (this?.device?.isMobile) {
        cssClass = 'mobile';
      }
      return cssClass;
    },
    currentSlider() {
      return this.bannerItems?.slider?.find((slider) => slider.cssClass.includes(this.currentCssClass));
    },
    sliderBanners() {
      const currentSlider = { ...this.currentSlider };
      return currentSlider?.banners?.sort((a, b) => a.order - b.order) || [];
    },
  },
  methods: {
    handleVideoEnd(index) {
      const video = document.getElementById(`banner-video-${index}`);
      if (video) {
        video.currentTime = 0;
        video.play();
      }
      if (this.slider) {
        this?.slider?.next();
      }
    },
    playSlider() {
      if (this.slider) {
        this.slider.destroy();
        this.slider = null;
      }
      const sliderEle = this.$refs.bannerSlider;
      if (!sliderEle || this.sliderBanners.length <= 1) {
        return;
      }
      const slidesPerView = 1;
      this.slider = new KeenSlider(
        sliderEle,
        {
          initial: 0,
          disabled: false,
          drag: true,
          loop: true,
          slidesPerView,
          slideChanged: (s) => {
            this.current = s?.track?.details?.rel;
          },
        },
        [
          this.sliderPlugin,
        ],
      );
    },
    sliderPlugin(slider) {
      let timeout;
      const time = this.currentSlider?.speed || 7000;
      function clearNextTimeout() {
        clearTimeout(timeout);
      }
      function nextTimeout() {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          slider?.next();
        }, time);
      }
      slider.on('dragStarted', clearNextTimeout);
      slider.on('animationEnded', nextTimeout);
      slider.on('updated', nextTimeout);
    },
  },
  beforeDestroy() {
    this.slider?.destroy();
  },
  watch: {
    currentCssClass: {
      handler() {
        this.$nextTick(() => {
          this.playSlider();
        });
      },
      immediate: true,
    },
  },
};
